import { useMemo, useState } from 'react';
import c from 'classnames';
import {
  Area,
  AreaChart,
  Label,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
} from 'recharts';

import { getOrdinalSuffix } from 'helpers/getOrdinalSuffix';
import Kit, { KitType } from 'types/Kit';

import microbiome_score_reference_data from './microbiome_score_reference_data.json';
import styles from './MicrobiomeScoreChart.module.scss';

type ChartData = {
  score: number;
  value: number;
  color: 'red' | 'orange' | 'grey' | 'green';
  ab_perc: number;
}[];
const normalizeDataValues = (data: ChartData) => {
  const max = Math.max(...data.map(data => data.value));
  return data.map(data => ({
    ...data,
    value: data.value / max,
  }));
};
export const chartDataForKit = (kit: Kit): ChartData => {
  const { sampling_type, age_at_sampling } = kit;

  if (!sampling_type || !age_at_sampling) {
    return [];
  }
  let result = [];
  if (sampling_type === KitType.VAGINAL) {
    result = microbiome_score_reference_data['vaginal_adult'] as ChartData;
  } else if (age_at_sampling < 365) {
    result = microbiome_score_reference_data['stool_baby'] as ChartData;
  } else if (age_at_sampling < 1095) {
    result = microbiome_score_reference_data['stool_toddler'] as ChartData;
  } else if (age_at_sampling < 6570) {
    result = microbiome_score_reference_data['stool_child'] as ChartData;
  } else {
    result = microbiome_score_reference_data['stool_adult'] as ChartData;
  }
  return normalizeDataValues(result);
};
const LinearGradient = ({ id, color }: { id: string; color: string }) => (
  <linearGradient id={id} x1='0' y1='0' x2='0' y2='1'>
    <stop offset='0%' stopColor={color} stopOpacity={1} />
    <stop offset='100%' stopColor={color} stopOpacity={0.6} />
  </linearGradient>
);
const ReferenceLabel = ({
  x,
  y,
  percentile,
  score,
}: {
  x: number;
  y: number;
  percentile: number;
  score?: number;
}) => {
  return (
    <>
      <rect
        x={x - 75}
        y={y - 20}
        width={150}
        height={40}
        fill='white'
        stroke='var(--warm-grey-400)'
        strokeWidth={1}
      />
      <text
        x={x}
        y={y}
        dy={-2}
        dx={-12}
        fontSize={12}
        textAnchor='middle'
        fontWeight={700}
      >
        {`${percentile.toFixed(2)}${getOrdinalSuffix(
          Number(percentile.toFixed(2).slice(-1)),
        )} percentile`}
      </text>
      <text
        x={x}
        y={y}
        dy={12}
        dx={-13}
        fontSize={10}
        textAnchor='middle'
        fontWeight={300}
      >
        Microbiome Score:
      </text>
      <text x={x} y={y} dy={12} dx={42} fontSize={10} fontWeight={700}>
        {score}
      </text>
    </>
  );
};
const Legend = ({ isVaginal }: { isVaginal?: boolean }) => {
  return (
    <div className='flex flex-column gap-2 f7 medium'>
      <div className='flex align-center gap-1'>
        <div className={c(styles.red, styles.legendRect)} />
        {isVaginal ? 'Lowest 5%' : 'Lowest 20%'}
      </div>
      <div className='flex align-center gap-1'>
        <div className={c(styles.orange, styles.legendRect)} />
        Below median
      </div>
      <div className='flex align-center gap-1'>
        <div className={c(styles.grey, styles.legendRect)} />
        Above median
      </div>
      <div className='flex align-center gap-1'>
        <div className={c(styles.green, styles.legendRect)} />
        {isVaginal ? 'Top 1%' : 'Top 10%'}
      </div>
    </div>
  );
};
const MicrobiomeScoreChart = ({
  kit,
  isModal,
  isImage,
  popScore,
  disableAnimations,
}: {
  kit: Kit;
  isModal?: boolean;
  isImage?: boolean;
  popScore: number;
  disableAnimations?: boolean;
}) => {
  const constant_offset = 0.005;
  const [{ width, height }, setSize] = useState({ width: 0, height: 0 });
  const [showLabel, setShowLabel] = useState(false);
  const chartData = chartDataForKit(kit);
  const isVaginal = kit.sampling_type === KitType.VAGINAL;
  const formattedByColor = chartData.map(data => ({
    [data.color]: Math.max(data.value, constant_offset),
    score: data.score,
  }));
  const popScoreData = chartData.find(data => data.score === popScore);
  const percentile = popScoreData?.ab_perc;
  const referenceDotCoordinates = {
    x: popScore,
    y: popScoreData?.value ?? 0,
  };
  const maxValue = Math.max(...chartData.map(data => data.value));
  const refDotX = (popScore * width) / 100;
  const refDotY =
    height -
    30 -
    ((popScoreData?.value ?? constant_offset) * (height - 30)) / maxValue;
  const referenceDotLabelCoordinates = {
    x: Math.max(Math.min(refDotX, width - 75), 75),
    y: refDotY > 80 ? refDotY - 40 : refDotY + 40,
  };
  // get the proportion of each chart area to define the location of the gaps between chart areas.

  const gapProportions = useMemo(() => {
    let currentColor: 'red' | 'orange' | 'grey' | 'green' = 'red';
    return chartData.reduce(
      (
        acc: { red: number; orange: number; grey: number; green: number },
        data,
        index,
      ) => {
        if (data.color !== currentColor) {
          const proportion = index / chartData.length;
          acc[data.color] = proportion;
          currentColor = data.color;
        }
        return acc;
      },
      { red: 0, orange: 0, grey: 0, green: 0 },
    );
  }, [chartData]);
  // safari won't rerender the gap masks on resize, so we need to give them unique ids
  const gapIds = {
    red: `redGap${width}`,
    orange: `orangeGap${width}`,
    grey: `greyGap${width}`,
    green: `greenGap${width}`,
  };
  return (
    <>
      <ResponsiveContainer
        width={'100%'}
        height={'100%'}
        minHeight={isImage ? 265 : 200}
        minWidth={isImage ? 275 : 200}
        onResize={(width, height) => {
          setSize({ width, height });
        }}
      >
        <AreaChart
          className={styles.popChart}
          data={formattedByColor}
          {...{ overflow: 'visible' }}
          stackOffset='expand'
          margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <LinearGradient id='red' color='var(--red-300)' />
            <LinearGradient id='orange' color='var(--orange-300)' />
            <LinearGradient id='grey' color='var(--warm-grey-400)' />
            <LinearGradient id='green' color='var(--green-400)' />
            <mask id={gapIds.orange}>
              <rect x='0' y='0' width='100%' height='100%' fill='white' />
              <rect
                x='4'
                y='0'
                width={`${gapProportions.orange * 100}%`}
                height='100%'
                fill='black'
              />
            </mask>
            <mask id={gapIds.grey}>
              <rect x='0' y='0' width='100%' height='100%' fill='white' />
              <rect
                x='4'
                y='0'
                width={`${gapProportions.grey * 100}%`}
                height='100%'
                fill='black'
              />
            </mask>
            <mask id={gapIds.green}>
              <rect x='0' y='0' width='100%' height='100%' fill='white' />
              <rect
                x='4'
                y='0'
                width={`${gapProportions.green * 100}%`}
                height='100%'
                fill='black'
              />
            </mask>
          </defs>
          <Area
            className={styles.area}
            dataKey='red'
            fillOpacity='1'
            fill={'url(#red)'}
            stroke='var(--red-200)'
            strokeWidth={2}
            strokeOpacity={0.66}
            isAnimationActive={!disableAnimations}
          />
          <Area
            className={styles.area}
            dataKey='orange'
            fillOpacity='1'
            fill={'url(#orange)'}
            stroke='var(--orange-200)'
            strokeWidth={2}
            strokeOpacity={0.66}
            isAnimationActive={!disableAnimations}
            mask={isModal || isImage ? '' : `url(#${gapIds.orange})`}
          />
          <Area
            className={styles.area}
            dataKey='grey'
            fillOpacity='1'
            fill='url(#grey)'
            stroke='var(--warm-grey-300)'
            strokeWidth={2}
            strokeOpacity={0.66}
            isAnimationActive={!disableAnimations}
            mask={isModal || isImage ? '' : `url(#${gapIds.grey})`}
          />
          <Area
            className={styles.area}
            dataKey='green'
            fillOpacity='1'
            fill='url(#green)'
            stroke='var(--green-200)'
            strokeWidth={2}
            strokeOpacity={0.66}
            isAnimationActive={!disableAnimations}
            mask={isModal || isImage ? '' : `url(#${gapIds.green})`}
          />
          {isModal && (
            <>
              <rect
                height='100%'
                width='4px'
                x={`${gapProportions.orange * width}px`}
                y={0}
                fill='white'
              />
              <rect
                height='100%'
                width='4px'
                x={`${gapProportions.grey * width}px`}
                y={0}
                fill='white'
              />
              <rect
                height='100%'
                width='4px'
                x={`${gapProportions.green * width}px`}
                y={0}
                fill='white'
              />
            </>
          )}
          {isModal && (
            <ReferenceLine
              x={popScore}
              stroke='black'
              strokeDasharray='3 3'
            ></ReferenceLine>
          )}
          <ReferenceDot
            x={referenceDotCoordinates.x}
            y={referenceDotCoordinates.y}
            r={6}
            fill='var(--purple-200)'
            className={styles.referenceDot}
            onMouseEnter={() => setShowLabel(true)}
            onMouseLeave={() => setShowLabel(false)}
            // isFront
          >
            {(isModal || showLabel) && (
              <Label
                content={
                  <ReferenceLabel
                    score={popScore}
                    x={referenceDotLabelCoordinates.x}
                    y={referenceDotLabelCoordinates.y}
                    percentile={Math.max(1, percentile ?? 1)}
                  />
                }
              />
            )}
          </ReferenceDot>
          <XAxis
            dataKey='score'
            interval={isModal ? 249 : 999}
            tickLine={false}
            strokeWidth='0'
            // hide
          >
            {!isModal && <Label value='Microbiome summary score' />}
          </XAxis>{' '}
        </AreaChart>
      </ResponsiveContainer>
      {isModal && (
        <div className={styles.popChartLegend}>
          <Legend isVaginal={isVaginal} />
        </div>
      )}
    </>
  );
};

export default MicrobiomeScoreChart;
