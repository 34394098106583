import { useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion, useDragControls, useMotionValue } from 'framer-motion';

import { LINKS } from 'assets';
import useFeature from 'services/features/useFeature';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { getIsAnyTinyPlus, getIsTinyPlus } from 'store/account';
import { getHomePageTinyAccountId } from 'store/home';
import TinyAccount from 'types/TinyAccount';

import Typography from 'components/Typography/Typography';
import { Brand, Icon, TinyAccountSelector } from 'components';
import {
  ButtonVariant,
  LinkButton,
} from 'components/DesignButton/DesignButton';
import SidebarItem from 'components/Sidebar/SidebarItem';
import { useSidebarItems } from 'components/Sidebar/useSidebarItems';

import styles from './MobileMenu.module.scss';

const MobileMenu = ({ toggleMobileMenu }: { toggleMobileMenu: () => void }) => {
  const navigate = useNavigate();
  const dragControls = useDragControls();
  const x = useMotionValue(0);
  const { isFeatureEnabled: isHomeScreenEnabled } = useFeature('home_screen');
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  const homePageTinyAccountId = useSelector(getHomePageTinyAccountId);
  const sideBarItems = useSidebarItems({ kitId: currentKitId, isMobile: true }); // not currently necessary for this component to have kitID here
  const tinyAccountSelected = useCallback(
    async (tinyAccount?: Partial<TinyAccount>) => {
      if (tinyAccount?.id) {
        navigate(`/`, { state: { tinyAccountId: tinyAccount.id } });
      }
    },
    [navigate],
  );
  return (
    <>
      <motion.div
        className={styles.menuOverlay}
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        exit={{ opacity: 0 }}
        transition={{
          ease: 'linear',
          duration: 0.125,
        }}
        onClick={toggleMobileMenu}
      />
      <motion.div
        onClick={e => e.stopPropagation()}
        className={styles.mobileMenu}
        initial={{ right: '-100%' }}
        animate={{ right: '0%' }}
        exit={{ right: '-100%' }}
        transition={{
          ease: 'easeInOut',
          duration: 0.25,
        }}
        style={{ x }}
        onDragEnd={() => {
          if (x.get() > 50) {
            toggleMobileMenu();
          }
        }}
        drag='x'
        dragListener={false}
        dragControls={dragControls}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={{ left: 0, right: 0.5 }}
      >
        <div
          className={styles.dragToClose}
          onPointerDown={e => {
            dragControls.start(e);
          }}
        />
        <div className={styles.container}>
          <div className={styles.topRow}>
            <div className={styles.logo}>
              <Brand />
            </div>
          </div>

          <div className={styles.middleMenuItems}>
            {isHomeScreenEnabled && (
              <div className='mt-4'>
                <Typography variant={'heading-xl'}>Your family</Typography>
                <TinyAccountSelector
                  onSelection={tinyAccountSelected}
                  currentTinyAccountId={
                    currentTinyAccountId ?? homePageTinyAccountId
                  }
                  withProfile
                />
              </div>
            )}
          </div>
          <div className={styles.bottomMenuItems}>
            {sideBarItems.map((item, index) => (
              <SidebarItem
                key={index}
                to={item.to}
                icon={item.icon}
                label={item.label}
                onClick={toggleMobileMenu}
              />
            ))}
          </div>
        </div>
      </motion.div>
    </>
  );
};

const MobileMenuForHomeProject = ({
  toggleMobileMenu,
}: {
  toggleMobileMenu: () => void;
}) => {
  const navigate = useNavigate();
  const isTinyPlus = useSelector(getIsTinyPlus);
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);

  const tinyAccountSelected = useCallback(
    async (tinyAccount?: Partial<TinyAccount>) => {
      if (tinyAccount?.id) {
        navigate(`/`, { state: { tinyAccountId: tinyAccount.id } });
      }
    },
    [navigate],
  );

  return (
    <>
      <motion.div
        className={styles.menuOverlay}
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        exit={{ opacity: 0 }}
        transition={{
          ease: 'linear',
          duration: 0.125,
        }}
        onClick={toggleMobileMenu}
      />
      <motion.div
        className={styles.mobileMenu}
        initial={{ right: '-80vw' }}
        animate={{ right: 0 }}
        exit={{ right: '-80vw' }}
        transition={{
          ease: 'linear',
          duration: 0.25,
        }}
      >
        <div className={styles.topRow}>
          <div className={styles.logo}>
            <Brand />
          </div>
          <div className={styles.close} onClick={toggleMobileMenu}>
            <Icon name='closeSm' />
          </div>
        </div>
        <div className={styles.middleMenuItems}>
          <Typography variant={'heading-xl'}>Your family</Typography>
          <TinyAccountSelector onSelection={tinyAccountSelected} withProfile />
        </div>
        <div className={styles.bottomMenuItems}>
          <NavLink to='/account' onClick={toggleMobileMenu}>
            <div className={styles.menuItem}>
              <Icon name='userSingle' />
              <Typography variant={'heading-s'}>Account</Typography>
            </div>
          </NavLink>

          <NavLink to='/guides' onClick={toggleMobileMenu}>
            <div className={styles.menuItem}>
              <Icon name='book' />
              <Typography variant={'heading-s'}>Guides</Typography>
            </div>
          </NavLink>

          <LinkButton
            label={isAnyTinyPlus ? 'Tiny+ store' : 'Store'}
            href={
              isAnyTinyPlus
                ? isTinyPlus
                  ? LINKS.checkoutWithKitTinyPlus
                  : LINKS.checkoutWithKitTinyPlusProgramOrProactive
                : LINKS.store
            }
            external
            variant={ButtonVariant.WRAPPER}
          >
            <div className={styles.menuItem}>
              <Icon name='shoppingCart' />
              <Typography variant={'heading-s'}>
                {isAnyTinyPlus ? 'Tiny+ store' : 'Store'}
              </Typography>
            </div>
          </LinkButton>

          <NavLink to='/bookmarks' onClick={toggleMobileMenu}>
            <div className={styles.menuItem}>
              <Icon name='bookmark' />
              <Typography variant={'heading-s'}>Bookmarks</Typography>
            </div>
          </NavLink>

          <NavLink to='/refer-a-friend' onClick={toggleMobileMenu}>
            <div className={styles.menuItem}>
              <Icon name='gift' />
              <Typography variant={'heading-s'}>Invite a friend</Typography>
            </div>
          </NavLink>

          <NavLink to='/help' onClick={toggleMobileMenu}>
            <div className={styles.menuItem}>
              <Icon name='help' />
              <Typography variant={'heading-s'}>Need help?</Typography>
            </div>
          </NavLink>
        </div>
      </motion.div>
    </>
  );
};

export default MobileMenu;
