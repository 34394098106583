import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { sortKitsByResultsViewableOrLatest } from 'helpers';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { useHeaderIllustration } from 'contexts/HeaderIllustrationContext';
import { getKitsForTinyAccountId } from 'store/kits';
import useProfileSurveys from 'services/surveys/useProfileSurveys';
import useFeature from 'services/features/useFeature';
import { getTinyAccounts } from 'store/account';
import { getKits } from 'store/shared';

import { Spinfinity, Header, Typography } from 'components';
import HeaderIllustration from 'components/Header/HeaderIllustration';
import { NoKitRegistered, NoKitResults } from '.';
import { ResultsReadyRoutes } from './ResultsReadyRoutes';
import { SurveysRoutes } from './Surveys/SurveysRoutes';
import { KitProcess } from './KitProcess/KitProcess';
import { KitProcessWithProfile } from './KitProcessWithProfile/KitProcessWithProfile';
import ConsultsInfoPage from 'views/Consults/ConsultsInfo/ConsultsInfoPage';
import ConsultsBooking from 'views/Consults/ConsultsBooking/ConsultsBooking';

import styles from './Results.module.scss';

export type ResultsParams = {
  kitId: string;
};

export const ResultsPage = () => {
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const { isFeatureEnabled: isHomeScreenEnabled } = useFeature('home_screen');
  const {
    currentKit,
    currentKitId,
    currentTinyAccount,
    currentKitHasResults,
    currentTinyAccountId,
  } = useCurrentKitAndTinyAccount();
  const kits = useSelector(getKits);
  const tinyAccounts = useSelector(getTinyAccounts);
  const defaultKitId = sortKitsByResultsViewableOrLatest(kits)[0]?.id;
  const kitsForCurrentTinyAccount = useSelector(
    getKitsForTinyAccountId(currentTinyAccountId),
  );
  const { isNewProfileFlow, needsSurveys } = useProfileSurveys(
    currentTinyAccountId,
    currentKitId,
  );
  const completedSurveys = !!currentKit?.date_survey_completed; //typeform surveys completed and before that the profile and sample info too
  const { url, bgColor } = useHeaderIllustration();
  let NextAction = <ResultsReadyRoutes />;
  if (defaultKitId && !currentKitId) {
    NextAction = <Navigate to={`/results/${defaultKitId}`} replace />;
  } else if (!kitsForCurrentTinyAccount?.length) {
    NextAction = <NoKitRegistered />;
  } else if (
    !isHomeScreenEnabled &&
    showProfiles &&
    isNewProfileFlow &&
    (!currentKitHasResults || !completedSurveys)
  ) {
    //we can also check here for missingBirthMother if we want to make it block results again
    NextAction = <KitProcessWithProfile />;
  } else if (
    !isHomeScreenEnabled &&
    (!showProfiles || !isNewProfileFlow) &&
    (!currentKitHasResults || needsSurveys)
  ) {
    //we can also check here for missingBirthMother if we want to make it block results again
    NextAction = <KitProcess />;
  } else if (!currentKitHasResults) {
    NextAction = <NoKitResults />;
  }

  return (
    <>
      <Header title={`${currentTinyAccount?.first_name}’s results`} />
      <Suspense fallback={<Spinfinity />}>
        {url && <HeaderIllustration url={url} bgColor={bgColor} />}
        <div className={styles.contentWrapper}>
          <div className={styles.resultsContainer} id='results-container'>
            <div className={'p-4'}>{NextAction}</div>
            {currentKitHasResults && (
              <footer className={styles.resultsFooter}>
                <Typography variant='body-s' serif>
                  Note: This report is not a diagnosis. It reflects the results
                  of a wellness microbiome test and are intended to educate you
                  about the microbes in your body.
                </Typography>
                <Typography variant='body-s' serif>
                  The microbes we talk about in your report, and their
                  associations, are based on emerging scientific research. Your
                  report insights may change over time to reflect the most
                  up-to-date research and data analysis. Additional sections may
                  appear as we launch new insights.
                </Typography>
                <Typography variant='body-s' serif>
                  Tiny Health tests are not intended to diagnose or treat
                  disease, and are not a substitute for professional medical
                  advice. Feel free to share this with your practitioner and
                  always consult with them before implementing a new dietary or
                  supplement change.
                </Typography>
              </footer>
            )}
          </div>
        </div>
      </Suspense>
    </>
  );
};

export const ResultsRoutes = () => {
  return (
    <Routes>
      <Route path='' element={<ResultsPage />} />
      <Route path=':kitId/*' element={<ResultsPage />} />

      <Route path=':kitId/consults' element={<ConsultsInfoPage />} />
      <Route path=':kitId/consults/book' element={<ConsultsBooking />} />

      <Route path=':kitId/surveys/*' element={<SurveysRoutes />} />
    </Routes>
  );
};
